<template>
  <div class="home" id="home">
    <!-- 活动引导页 -->
    <div v-if="guideFlag">
      <guidePage @closeGuide="closeGuide"></guidePage>
    </div>

    <div v-else>
      <section class="banner">
        <van-swipe
          class="swiper-carousel"
          lazy-render
          :autoplay="3000"
          :show-indicators="true"
          indicator-color="#fff"
          @change="onChange"
        >
          <van-swipe-item
            v-for="(item, index) in bannerList"
            :key="index"
            @click="openBanner(item,index)"
          >
            <!-- @click="handleClick" -->
            <van-image
              fit="cover"
              @click="toBannerDetail(item)"
              class="lazy_img"
              :src="item.img ?? ''"
            />
          </van-swipe-item>
        </van-swipe>

        <!--          <ul class="indicators indicator">-->
        <!--            <li v-for="(item, index) in bannerList" :class=" bannerActive == index ? 'active' : ''" :key="index" ></li>-->
        <!--          </ul>-->
      </section>

      <section class="home-list">
        <van-tabs @change="changeVanTabs" v-model:active="activeTab" shrink sticky swipeable>
          <van-tab v-for="title in tabs" :title="title.dictValue">
            <van-list
              v-model:loading="loading"
              v-model:error="error"
              :finished="finished"
              finished-text
              error-text="加载出错,请重新加载"
              @load="onLoad"
            >
              <!-- 狸好！星城 -->
              <div v-if="title.dictKey == 'helloStarCity'">
                <div
                  v-if="typeDict['helloStarCity']  && typeDict['helloStarCity'].length > 0"
                  class="top-banner"
                  @click="toBannerDetail(typeDict['helloStarCity'][0])"
                >
                  <img :src="typeDict['helloStarCity'][0].img" alt />
                </div>

                <div v-for="item in list" class="video-item" @click="toPlayVideo(item)">
                  <div class="top-video">
                    <van-image
                      class="video-img"
                      radius="8px"
                      fit="cover"
                      :src="item.contentThumbnailImage"
                    />

                    <div class="bottom-video">
                      <div class="play-btn">
                        <img src="@/assets/home/icon_video_play.png" alt />
                      </div>
                      <div class="like">
                        <img src="@/assets/home/icon_zan.png" alt />
                        <p>{{item.likeNum}}</p>
                      </div>
                    </div>
                  </div>
                  <p class="title">{{item.title}}</p>
                </div>
              </div>

              <!-- 星城徽章 / 这礼是长沙 -->
              <div v-else-if="title.dictKey == 'starCityBadge' || title.dictKey == 'thisCS'">
                <div
                  v-if="(title.dictKey == 'thisCS' && typeDict &&  typeDict['thisCS'].length > 0) || (title.dictKey == 'starCityBadge' && typeDict &&  typeDict['starCityBadge'].length > 0)"
                  class="top-banner"
                >
                  <img
                    :src="title.dictKey == 'thisCS' ? typeDict['thisCS'][0].img : typeDict['starCityBadge'][0].img"
                    alt
                    @click="toBannerDetail(title.dictKey == 'thisCS' ? typeDict['thisCS'][0] : typeDict['starCityBadge'][0])"
                  />
                </div>
                <!-- 跳转徽章详情 -->
                <div v-for="item in list" class="digital-item" @click="toProductDetail(item)">
                  <div class="top-video">
                    <van-image radius="8px 8px 0 0" class="digital-img" :src="item.images" />

                    <div
                      class="top-time"
                      :class="item.activityStatus < 5 ? 'wks-bg' : item.statusUser > 5 ? 'end-bg' : '' "
                    >
                      <!-- 倒计时icon 进行中/未开始/已结束 -->
                      <img
                        v-if="item.activityStatus == 5"
                        src="@/assets/home/icon_home_wait.png"
                        alt
                      />
                      <img
                        v-else-if="item.activityStatus < 5"
                        src="@/assets/home/icon_home_wait_1.png"
                        alt
                      />
                      <img v-else src="@/assets/home/icon_home_wait_2.png" alt />
                      <p v-if="item.activityStatus == 5">进行中</p>
                      <p v-else-if="item.activityStatus < 5">未开始</p>
                      <p v-else>活动已结束</p>
                    </div>
                  </div>
                  <div class="bottom-info">
                    <p style="font-weight: bold">{{item.name}}</p>
                    <button class="btn" style="font-weight: bold;background: #F49B79;" v-if="this.drawStatus == true || item.isBuy == true || (this.receive == true && item.id == activeProductId)">您已领取</button>
                    <button class="btn" style="font-weight: bold;background: #F49B79;" v-else-if="item.activityStatus > 5">活动已结束</button>
                    <button class="btn" style="font-weight: bold;background: #F49B79;" v-else-if="item.activityStatus < 5">活动暂未开始</button>
                    <button class="btn" style="font-weight: bold" v-else>免费领取</button>

                  </div>
                </div>
              </div>

              <!-- 星城地图 -->
              <div v-else-if="title.dictKey == 'starCityMap'">
                <div
                  v-if="typeDict['starCityMap']  && typeDict['starCityMap'].length > 0"
                  class="top-banner"
                  @click="toBannerDetail(typeDict['starCityMap'][0])"
                >
                  <img :src="typeDict['starCityMap'][0].img" alt />
                </div>

                <div v-for="item in list" class="map-item">
                  <van-image radius="8px" class="map-img" :src="item.mapImageThumbnails" />
                  <button
                    class="btn"
                    @click="toBigMap(item.wenwuMapId)"
                    style="font-weight: bold"
                  >点击查看</button>
                </div>
              </div>
            </van-list>

            <div class="botton-introduce">
              <div>
                <img class="chain-icon" src="../../assets/icons/chain_logo_big.png" />
              </div>
              <div class="chain-botton">提供区块链技术服务支持</div>
            </div>
          </van-tab>
        </van-tabs>
      </section>

      <!-- <van-popup v-model:show="showNewfature">
        <div class="newFeature">
          <img v-if="step == 0" src="@/assets/icon_newFeature1.png" alt />
          <img v-else-if="step == 1" src="@/assets/icon_newFeature2.png" alt />
          <img v-else-if="step == 2" src="@/assets/icon_newFeature3.png" alt />
          <img v-else src="@/assets/icon_newFeature4.png" alt />

          <div v-if="step == 3" class="btn-end">
            <button @click="share">分享领徽章</button>
            <button @click="nextStep">立即体验</button>
          </div>
          <button v-else class="step" @click="nextStep">下一步</button>
        </div>
      </van-popup> 

      <van-popup v-model:show="showGetDigital">
        <div class="pop-getSuccess" @click="showGetDigital = !showGetDigital">
          <div class="digital-success">
            <img src="@/assets/home/icon_video_getSuccess.png" alt />
            <button @click="toMyDigitalBadge"></button>
          </div>
        </div>
      </van-popup> -->

      <!-- 答题弹窗 遮罩层 -->
      <!-- <div v-if="popover" class="popover">
          <AnswerPopup ></AnswerPopup>
      </div>-->
<!-- 
      <van-popup v-model:show="AnswerShow">
        <AnswerPopup
          ref="child"
          @closeAnswer="closeAnswer"
          @changIsShow="changIsShow"
          @rightAnswers="rightAnswers"
          @receiveStatus="receiveStatus"
          class="popover"
        ></AnswerPopup>
      </van-popup> -->

      <!-- 暂不领取弹窗 -->
      <!-- <van-popup v-model:show="isShow">
        <div class="wrapper">
          <div class="prompt">
            <div>确认不领取数字纪念徽章吗</div>
            <div class="botton">
              <div @click="closeAnswer">暂不领取</div>
              <div @click="isShow = false" style="  color: rgba(0, 87, 31, 1);">继续答题</div>
            </div>
          </div>
        </div>
      </van-popup> -->

      <tabbar></tabbar>
    </div>
  </div>
</template>
<script>
import { setTimeout } from 'timers'
import { clearToast, getStore, setStore, showFailToast, showLoading } from "../../utils/util";
import { Dialog } from "vant";
import router from "../../router";
import { onMounted, onUnmounted } from "vue";
import { shareInfo } from "../../api/user";
import store from "../../store";
// import AnswerPopup from "../../components/answerPop-up/";
import guidePage from "../../components/guide_page/"
import { log } from 'util';
export default {
  name: 'newHome',
  components: {
    // AnswerPopup,
    guidePage
  },

  data () {
    return {
      drawStatus:false,
      activeProductId:null,
      isShow: false,
      // 是否开启引导页
      guideFlag: false,
      popover: true,
      showGetDigital: false,
      step: 0,
      AnswerShow: false,
      receive:false,
      activeTab: 0,
      show: false,
      localUrl: window.location,
      bannerActive: 0,
      bannerList: [],
      tabs: [],
      list: [],
      typeDict: {},
      config: {},
      timer: false,
      offset: 0,
      limit: 10,
      currentName: 0,
      currentType: '',
      loading: false,
      finished: false,
      error: false,
      showNewfature: false,
      requestCount: 0
    }
  },
  mounted () {
    document.addEventListener("visibilitychange", this.func);
    // this.deleteRouterShow()
    // const _this = this
    // var datas = sessionStorage.getItem('guideShow');
    // if (datas == null) {
    //   sessionStorage.setItem('guideShow', true)
    // }
    // if (datas == 'false') {
    //   _this.guideFlag = false
    //   // _this.AnswerShow = true
    // }
    // this.getUserActive()


  },
  //  路由加载之前 钩子函数
  beforeRouteEnter (to, from, next) {
    next(vm => {          //  这里的vm指的就是vue实例，可以用来当做this使用
      // 从藏品详情过来的就展示答题,并传递isShow=true 就展示答题   
      if (from.name == "nftDetail" && to.query.isShow) {
        setTimeout(() => {
          this.AnswerShow = true
          this.deleteRouterShow()
          this.$refs.child.closeAll()
        }, 1000);
      }
      if (from.name =="nftDetail") {
          this.list = []
          this.offset = 0
        this.onLoad()
      }
    })
  },


  unmounted () {

    document.removeEventListener("visibilitychange", this.func)


  },
  created () {
        //   先判断是否需要开启518引导页
    this.closeGuiSession()
    this.loadData();

    //  判断是否需要开启星城玩家引导页
    if (getStore('isNew') != 'true') {
      this.showNewfature = true;
      setStore('isNew', true)
    }else{
      // 查看用户是否参与过518活动 进行展示答题弹框
    }
    this.getUserActive()

    // this.$http.get('/wenwu-commodity/dict/detail?dict_key=shareBadge').then(res => {
    //
    //   if(res.data.code == 200){
    //     this.showNewfature = false;
    //     this.showGetDigital = false;
    //
    //     this.getDigitalBadgeProduct(res.data.data.dictValue);
    //
    //     clearToast()
    //
    //   }else{
    //     showFailToast(res.data.message,1000);
    //   }
    // })

    //埋点上报
    eshimin.bytedanceApplogEvent({
      eventName: 'page_detail', // 例子
      eventParams: { 'page_name': '首页', 'title_name': '首页' },//例子
      onResponse: (res) => {
        console.log("bytedanceApplogEvent responseData:" + res);
      }
    })

  },


  methods: {

    changDrawStatus(){
      console.log(1111111);
      this.drawStatus = true
    },
    receiveStatus(){
      this.receive = true
    },
    closeGuiSession(){
      var datas = sessionStorage.getItem('guideShow');
      if (datas == null || datas == 'true') {
        this.$http.get('/wenwu-commodity/dict/detail?dict_key=questionsBadge').then(res => {
          this.$http.get("/wenwu-user/order/showQuestionsBadge?productId=" + res.data.data.dictValue).then(respnse => {
            if (respnse.data.code != 6052) {
              sessionStorage.setItem('guideShow', false)
              // this.guideFlag = true
            }else{
              this.guideFlag = false
              sessionStorage.setItem('guideShow', 'false')
            }
          })
        });
      }
      if (datas == 'false') {
        this.guideFlag = false
      }
    },
    deleteRouterShow () {
      this.$router.push({ query: {} });
    },
    //  查看用户是否参与过518活动
    getUserActive () {
      this.$http.get('/wenwu-commodity/dict/detail?dict_key=questionsBadge').then(res => {
        if (res.data.code == 200) {
          this.activeProductId = res.data.data.dictValue

          this.$http.get("/wenwu-commodity/product/info?product_id=" + res.data.data.dictValue).then(respnse => {
            if (respnse.data.data.isBuy == true) {
              this.AnswerShow = false
              this.receive = true
            } else {
              this.AnswerShow = true
              this.receive = false
            }
          })

        }
      })
    },
    // 答对题目 关闭组件，展示点击查看组件
    rightAnswers () {
      this.AnswerShow = false;
      alert('答对了')

    },
    //  是否关闭弹窗
    changIsShow () {
      this.isShow = true
    },
    // 活动引导页是否开启
    // closeGuide () {
    //   sessionStorage.setItem('guideShow', false)
    //   this.guideFlag = false;
      
    //   // this.AnswerShow = true;
    //   // this.getUserActive()
    //   // this.getUserActive()
    // },
    // 答题弹框是否开启
    closeAnswer () {
      this.AnswerShow = false;
      this.isShow = false
      // this.receive = true
      // this.$refs.child.rightAnswers = false //修改子组件data
    },

    func () {


      if (this.showNewfature == true && document.hidden == false) {

        this.$http.get('/wenwu-commodity/dict/detail?dict_key=shareBadge').then(res => {

          if (res.data.code == 200) {
            this.showNewfature = false;
            if (res.data.data.dictValue) {
              this.getDigitalBadgeProduct(res.data.data.dictValue);
            }


            clearToast()

          } else {
            showFailToast(res.data.message, 1000);
          }
        })

      }




    },

    toMyDigitalBadge () {
      this.$router.push('/digitalListPage')
    },

    share () {


      //埋点上报
      eshimin.bytedanceApplogEvent({
        eventName: 'share_click_button', // 例子
        eventParams: { 'share_name': shareInfo.title, 'title_name': '引导页分享' },//例子
        onResponse: (res) => {
          console.log("bytedanceApplogEvent responseData:" + res);
        }
      })

      eshimin.bytedanceApplogEvent({
        eventName: 'index_click_guide', // 例子
        eventParams: { 'guide_status': 1 },//例子
        onResponse: (res) => {
          console.log("bytedanceApplogEvent responseData:" + res);
        }
      })


      // this.func();
      // return;


      eshimin.share({
        version: 0,
        title: shareInfo.title,
        content: shareInfo.desc,
        imgUrl: shareInfo.logo,
        link: shareInfo.link,
        onResponse: function (res) {

          let codeJson = JSON.parse(res);
          if (codeJson.code == 200 && data.resultData.helloCityDail[0].productId) {//分享成功
            this.getDigitalBadgeProduct('100');
          }
          console.log("share responseData:" + res);
        }
      })

    },

    getDigitalBadgeProduct (id) {


      this.$http.post('/wenwu-user/order/showGetBadge', { 'hello_star_id': id }).then(res => {

        if (res.data.code == 200) {



          this.getSckillProductWithId(res.data.data.productId);


        } else {
          showFailToast(res.data.message, 1000);
        }
      }).catch((err) => {

        showFailToast(err.message, 1000);
      })
    },



    getSckillProductWithId (id) {
      this.$http.get("/wenwu-user/order/result?product_id=" + id).then(res2 => {

        if (res2.data.code == 6003) {
          if (this.requestCount < 5) {
            setTimeout(() => {
              this.requestCount++;
              this.getSckillProductWithId(id)
            }, 500)
          }

        } else {
          //调用支付接口，免费商品不会支付，只执行相关业务逻辑
          this.$http.post("/wenwu-user/pay/order", { orderId: res2.data.data, payType: 0 }).then((res3) => {

            if (res3.data.code == 200) {
              this.requestCount = 0
              clearToast()
              this.showGetDigital = true;

            }

          })
        }

      })
    },

    nextStep () {
      if (this.step == 3) {
        this.showNewfature = false
        eshimin.bytedanceApplogEvent({
          eventName: 'index_click_guide', // 例子
          eventParams: { 'guide_status': 2 },//例子
          onResponse: (res) => {
            console.log("bytedanceApplogEvent responseData:" + res);
          }
        })
        this.getUserActive()
      } else {
        this.step += 1
      }


    },

    toBannerDetail (item) {

      // if(item.detailImg != null && item.detailImg != ''){
      //   this.$router.push({name:'openBanner',query:{image:item.detailImg}});
      // }else if(item.url){
      //   window.location.replace(item.url);
      // }
    },

    toProductDetail (item) {

      this.$router.push({ path: '/nftDetail', query: { productId: item.id } })

    },

    onLoad () {

      this.loadListData();

    },

    changeVanTabs (name, title) {


      this.offset = 0
      this.list = [];
      this.currentName = name;
      this.currentType = this.tabs[name].dictKey;
      this.loadListData()

      eshimin.bytedanceApplogEvent({
        eventName: 'channel_click_home', // 例子
        eventParams: { 'channel_name': this.tabs[name].dictValue },//例子
        onResponse: (res) => {
          console.log("bytedanceApplogEvent responseData:" + res);
        }
      })

    },

    toPlayVideo (item) {
      router.push({ path: '/videoPlay', query: { 'id': item.helloStarId } })
    },

    toStoreDetail () {
      this.$router.push({ name: 'storeDetail', query: { id: this.storeList[this.selectIndex].id } });
    },
    openBanner (item, index) {

      //埋点上报
      eshimin.bytedanceApplogEvent({
        eventName: 'index_click_banner', // 例子
        eventParams: { 'banner_name': item.title, 'rank_num': index },//例子
        onResponse: (res) => {
          console.log("bytedanceApplogEvent responseData:" + res);
        }
      })


      if (item.detailImg != null && item.detailImg != '') {
        this.$router.push({ name: 'openBanner', query: { image: item.detailImg } });
      } else if (item.url.indexOf('/videoDetail') != -1) {
        this.$router.push({ name: 'videoDetail' });
      } else {
        window.location.href = item.url;
      }

    },
    toBigMap (id) {
      this.$router.push({ path: '/bigMap', query: { id: id } });
    },
    loadData () {

      // showLoading()
      this.$http.get("/wenwu-custom-services/wenwu/all").then(res => {
        console.log(res);
        if (res.data.code == 200) {
          this.bannerList = res.data.data.banner;


          this.tabs = res.data.data.table
          this.tabs.forEach((banner) => {
            this.typeDict[banner['dictKey']] = banner['bannerList']
          })

          clearToast()

          if (this.tabs.length > 0) {

            this.currentType = this.tabs[0].dictKey
            this.onLoad();
          }


        }

      })


    },


    loadListData () {

      showLoading()
      this.loading = true;
      this.error = false;
      this.finished = false;
      this.$http.get("/wenwu-commodity/product/homePageList?typeCode=" + this.currentType + "&offset=" + this.offset + "&limit=" + this.limit).then(res => {
        clearToast()
        this.loading = false;
        if (res.data.code == 200) {

          if (!res.data.data) {
            this.finished = true;
          } else {
            this.list.push(...res.data.data);
            this.offset += this.limit

            if (res.data.data.length < this.limit) {
              this.finished = true;
            } else {
              this.finished = false;
            }
          }

        } else {
          this.loading = false
          this.finished = false;
          this.error = true
        }




      }).catch((err) => {
        this.loading = false
        this.finished = false;
        this.error = true
        clearToast()
        showFailToast(err.message)
      })

    },
    onChange (index) {
      this.bannerActive = index;
    },

  }
}
</script>
<style scoped lang="scss" src="./index.scss">
</style>
<style scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.prompt {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 0.3rem;
  padding: 0.4rem;
  font-size: 13px;
  text-align: center;
  width: 55%;
}
.prompt .botton {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  padding: 0 6% 0 6%;
}
.wrapper {
  position: absolute;
  left: 50%;
  width: 100%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
